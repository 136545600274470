import { render, staticRenderFns } from "./RetroCarousel.vue?vue&type=template&id=61450176&scoped=true&"
import script from "./RetroCarousel.vue?vue&type=script&lang=js&"
export * from "./RetroCarousel.vue?vue&type=script&lang=js&"
import style0 from "./RetroCarousel.vue?vue&type=style&index=0&id=61450176&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61450176",
  null
  
)

export default component.exports