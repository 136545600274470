<template>
  <div>
    <BCarousel :indicator="false">
      <BCarouselItem v-for="n in 9" :key="n">
        <section>
          <div class="retro-carousel__image-container">
            <img :src="`/images/retro/${screenType}/${n}.png`" alt="retro" />
          </div>
        </section>
      </BCarouselItem>
    </BCarousel>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { BCarousel, BCarouselItem } from 'buefy/dist/esm/carousel';
export default {
  name: 'RetroCarousel',
  components: {
    BCarousel,
    BCarouselItem,
  },
  data() {
    return {
      screenType: 'desktop', // desktop or mobile
    };
  },
  watch: {
    displayWidth: {
      immediate: true,
      handler(newValue) {
        if (newValue > 600) {
          this.screenType = 'desktop';
        } else {
          this.screenType = 'mobile';
        }
      },
    },
  },
  computed: {
    ...mapState(['displayWidth']),
  },
};
</script>

<style scoped lang="scss">
.retro-carousel__image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 1rem;
  position: relative;
}
</style>
